<template>
<v-sheet>
  <v-navigation-drawer
    :value="value"
    absolute
    temporary
    @input="$emit('input', $event)"
  >
    <!-- <v-list
      nav
      dense
    >
      <v-list-item-group
        active-class="deep-purple--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-title>Foo</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>Bar</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>Fizz</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>Buzz</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list> -->
    <router-link :scroll="false" @logout="$emit('logout')" />
  </v-navigation-drawer>
</v-sheet>
</template>

<script>
export default {
  name: 'NavigationDrawer',
  components: {
    RouterLink: () => import('@/components/RouterLink')
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    // tt: function () {
    //   console.log(456465)
    // }
  }
}
</script>
